<template>
  <social-sharing
    :url="url"
    title="İleti Yönetim Sistemi"
    description="İlgili kanun ve yönetmelik çerçevesinde ticari elektronik ileti izinlerinin ve şikâyet süreçlerinin yönetilebildiği ulusal bir platformdur."
    quote="Ticari Elektronik İleti İzinleri tek noktada!"
    hashtags="ileti"
    twitter-user="iletiyonetim"
    inline-template
  >
    <div class="socialshare">
      <div class="sharetext"><img src="@/assets/img/ico_share.svg" alt="" />Paylaş</div>
      <div class="images">
        <!-- <network network="facebook"><img src="@/assets/img/icons/sm/sm-facebook.svg" alt=""/></network> -->
        <network network="linkedin"><img src="@/assets/img/icons/sm/sm-linkedin.svg" alt=""/></network>
        <!-- <network network="twitter"><img src="@/assets/img/icons/sm/sm-twitter.svg" alt=""/></network> -->
        <network network="twitter"><img src="@/assets/img/icons/sm/sm-x.svg" alt=""/></network>
        <network network="whatsapp"><img src="@/assets/img/icons/sm/sm-whatsapp.svg" alt=""/></network>
      </div>
    </div>
  </social-sharing>
</template>

<script>
export default {
  computed: {
    url() {
      return window.location.toString();
    }
  }
};
</script>

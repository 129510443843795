<template>
  <div class="page">
    <!-- <transition name="fade">
      <router-view style="top:100px;"></router-view>
    </transition>-->

    <!-- <modal-test v-if="showModal" @close="showModal = false"></modal-test> -->

    <div class="formcontainer staticpage">
      <div class="formbox">
        <h2>İleti Yönetim Sistemi Nasıl Çalışır?</h2>

            <social/>

        <div class="staticbox">
          <img src="@/assets/img/icons/nedir-01.svg" alt class="half" />

          <div class="half">
            <!-- <div class="number">#1</div> -->
            <h3>Ticari elektronik ileti izinleri için güvenli veri tabanı</h3>
            <p>
              Tüm ticari elektronik ileti izinleri, İleti Yönetim Sistemi'nde
              saklanır.
              <br />Ticari elektronik ileti gönderimi yapan tüm <b><tool-tip-hs></tool-tip-hs>lar</b>, pazarlama, promosyon, kampanya, indirim, hediye reklam vb. içerikli ticari elektronik iletilerini müşterilerine arama, mesaj ve e-posta olarak iletebilmek için aldıkları 
              ticari elektronik ileti izinlerini İleti Yönetim Sistemi'ne yükler.
              <br />Alıcılar
              yani
              <b>vatandaşlar</b> ise bu izinler için çıkış haklarını diledikleri
              zaman kullanacaktır.
            </p>
          </div>
        </div>

        <div class="staticbox">
          <img src="@/assets/img/icons/nedir-02.svg" alt class="half" />
          <div class="half">
            <!-- <div class="number">#2</div> -->
            <h3>Tek noktadan yönetim</h3>
            <p>
              Alıcılar, ne zaman, nereden, hangi kanal üzerinden hangi Hizmet Sağlayıcı'nın veya markasının ticari elektronik ileti göndermesine izin
              verdiğini görüntüleyecektir.
              <br />Hizmet Sağlayıcılar'a her bir kanal (<i>arama</i>,
              <i>mesaj</i> ve
              <i>e-posta</i>) için verdikleri izinleri İleti Yönetim Sistemi üzerinden düzenleyebilecektir.
              <br />Üstelik tüm bu işlemler tek bir
              platformda yapılacaktır.
            </p>
            <br />
          </div>
        </div>

        <div class="staticbox">
          <img src="@/assets/img/icons/nedir-03.svg" alt class="half" />
          <div class="half">
            <!-- <div class="number">#3</div> -->
            <h3>
              Ticari elektronik iletiler
              <br />kontrol altında
            </h3>
            <p>
              Hizmet Sağlayıcılar herhangi bir kanaldan ticari ileti göndermek istediklerinde, vatandaşların verdikleri izinler çeşitli yöntemlerle İleti Yönetim Sistemi üzerinden kontrol edilerek sadece izin vermiş vatandaşlara gönderim yapılacaktır.
              <br />Çıkış hakkının kullanıldığı durumlarda İleti Yönetim Sistemi filtresi sayesinde bu iletiler ret hakkını kullanmış alıcılara gönderilmeyecektir.
            </p>
          </div>
        </div>

        <div class="staticbox">
          <img src="@/assets/img/icons/nedir-04.svg" alt class="half" />
          <div class="half">
            <!-- <div class="number">#4</div> -->
            <h3>
              Şikâyet yönetimi
              <br />daha hızlı daha kolay
            </h3>
            <p>Ticari elektronik ileti almayı reddettikleri bir Hizmet Sağlayıcı'dan ileti alan vatandaşlar, şikâyet haklarını İleti Yönetim Sistemi üzerinden kullanabilecektir.</p>
          </div>
        </div>

        <div class="staticbox">
          <img src="@/assets/img/icons/nedir-05.svg" alt class="half" />
          <div class="half">
            <!-- <div class="number">#5</div> -->
            <h3>İletişim bilgileriniz güvende</h3>
            <p>
              İleti Yönetim Sistemi üzerindeki tüm izinler ve iletişim adresleri, ulusal veri tabanlarında saklanacak, böylelikle veri güvenliği sağlanacaktır.
            </p>
          </div>
        </div>
      </div>
    </div>




    <div class="staticcolumn">
      <div>
        <!-- <h3>Mevzuat</h3>
        <div class="ucebir">
          <h4>E-Ticaret Kanunu</h4>
          <p>6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun</p>
          <div class="btn second">
            <router-link to="/iys/kanun">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>15 Temmuz 2015 Tarihli Yönetmelik</h4>
          <p>Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇k</p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>04 Ocak 2020 Tarihli Yönetmelik</h4>
          <p>Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇kte
            Deği̇şi̇kli̇k Yapılmasına Dai̇r Yönetmeli̇k</p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik-degisiklik">Görüntüle</router-link>
          </div>
        </div> -->
      </div>
    </div>


  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import Vue from "vue";

import Social from "../../genel/Social";

export default {
  name: "IysNedir",
  components : { Social },

  methods: {
    ...mapMutations("app", ["SET_MODAL"])
  }
};
</script>

<style scoped>
.staticbox:nth-child(odd) {
  flex-direction: row-reverse;
}
.staticbox:nth-child(odd) p {
  text-align: right;
}

.staticbox:nth-child(odd) h3 {
  text-align: right;
}
.staticbox:nth-child(even) p {
  text-align: left;
}
.staticbox:nth-child(even) h3 {
  text-align: left;
}

.staticbox .number {
  font-weight: 900;
  font-style: italic;
  color: #eaeaea;
  position: absolute;
  z-index: -1;
  top: 5vh;
  font-size: 10vh;
}
.staticbox:nth-child(odd) .number {
  left: 0;
}
.staticbox:nth-child(even) .number {
  right: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/*  on phones ****************************************************************************************************************************************************************/

@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
  .staticbox .number {
    display: none;
  }
}
</style>
